
















import { Vue, Component } from 'vue-property-decorator';
import AlertsService, { AlertsServiceS } from '@/modules/alerts/alerts.service';
import { Inject } from 'inversify-props';
import AlertReference from '@/modules/alerts/components/alert-reference.vue';
import AlertPopupContent from '@/modules/alerts/components/alert-popup-content.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';

@Component({
    components: {
        AlertReference, AlertPopupContent, LoaderWrapper,
    },
})
export default class AlertComponent extends Vue {
    @Inject(AlertsServiceS) private alertsService!: AlertsService;

    public isShown = false;

    get amountAlerts(): number {
        const { alerts } = this.alertsService;
        return alerts ? alerts.filter(a => !a.read).length : 0;
    }

    public toggleAlerts() {
        this.isShown = !this.isShown;
        this.toggleListener();
    }

    private toggleListener() {
        if (this.isShown) {
            window.addEventListener('click', this.checkClickOutside);
            return;
        }

        window.removeEventListener('click', this.checkClickOutside);
    }

    private checkClickOutside(event: MouseEvent) {
        const { container } = this.$refs as { container: HTMLDivElement };
        const path = event.composedPath();

        if (path.includes(container)) return;
        this.toggleAlerts();
    }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomPopover',{attrs:{"haveArrow":false,"offset":30,"placement":"bottom-end","triggerEvent":"click","popoverClass":"profile-menu"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"profile-menu__label"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.label))]),_c('span',{attrs:{"name":"icon"}},[_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})])])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"profile-menu__content"},[_c('div',{staticClass:"profile-menu__user"},[_c('div',{staticClass:"profile-menu__userName"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"profile-menu__userEmail"},[_vm._v(_vm._s(_vm.email))])]),_vm._l((_vm.items),function(item,i){return [(item.link)?_c('router-link',{directives:[{name:"close-popover",rawName:"v-close-popover"}],key:i,class:{
                        'link': true,
                        'link_inactive': item.disable === true
                    },attrs:{"append":"","to":item.link}},[(typeof item.isVisible !== 'boolean' || item.isVisible)?_c('div',{class:{
                            'profile-menu__item': true,
                            'profile-menu__item--withHotel': item.children,
                        },on:{"click":function($event){item.action && item.action()}}},[_c('span',[_vm._v(_vm._s(item.text))]),_vm._l((item.children),function(child){return _c('div',{key:child.text,staticClass:"profile-menu__item--hotel"},[_vm._v(" "+_vm._s(child.text)),_c('i',{staticClass:"el-icon-arrow-right el-icon--right"})])})],2):_vm._e()]):_c('div',{directives:[{name:"close-popover",rawName:"v-close-popover"}],key:i,class:{
                        'link': true,
                        'link_inactive': item.disable === true
                    },attrs:{"append":"","event":item.disable !== true ? 'click' : ''}},[(typeof item.isVisible !== 'boolean' || item.isVisible)?_c('div',{class:{
                            'profile-menu__item': true,
                            'profile-menu__item--withHotel': item.children,
                        },on:{"click":function($event){item.action && item.action()}}},[_c('span',[_vm._v(_vm._s(item.text))]),_vm._l((item.children),function(child){return _c('div',{key:child.text,staticClass:"profile-menu__item--hotel"},[_vm._v(" "+_vm._s(child.text)),_c('i',{staticClass:"el-icon-arrow-right el-icon--right"})])})],2):_vm._e()])]})],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }










import { Vue, Prop, Component } from 'vue-property-decorator';
import { Badge } from 'element-ui';

@Component({
    components: {
        'el-badge': Badge,
    },
})
export default class CarsAlertReference extends Vue {
    @Prop({ required: true })
    private amountAlerts?: number = 0;
}

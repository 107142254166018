import FEATURES from '@/modules/common/constants/features.constant';

const FEATURES_ICONS = {
    [FEATURES.HOME]: 'icon-home-01',
    [FEATURES.RATE]: 'icon-CI_Rateshopper',
    [FEATURES.MARKET]: 'icon-CI_visibility',
    [FEATURES.GUEST_REVIEW]: 'icon-CI_Reviews',
    [FEATURES.EVENTS]: 'icon-CI_Events',
    [FEATURES.DEEP_COMPSET]: 'icon-CI_Deep-analisys-icon',
    [FEATURES.LITE_DI]: 'icon-DI-lite-icon',
    [FEATURES.PROMOTION_DETECTION]: 'icon-CI_Promotion',
};

export default FEATURES_ICONS;

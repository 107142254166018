























































































import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import { Inject } from 'inversify-props';
import CarsAlertItem from '@/modules/cars/alerts/components/cars-alert-item.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Checkbox } from 'element-ui';
import ConfirmPopup from '@/modules/common/components/popup-confirm.vue';
import CarsAlertsService, { CarsAlertsServiceS } from '../cars-alerts.service';

@Component({
    components: { CustomLoader, CarsAlertItem, 'el-checkbox': Checkbox, ConfirmPopup },
})
export default class CarsAlertPopupContent extends Vue {
    @Inject(CarsAlertsServiceS) alertsService!: CarsAlertsService;

    private isShowAll: boolean = false;
    private minAlerts = 5;
    private isSelectedAll: boolean = false;
    private selectedItems: string[] = [];
    private isSubSelectedItems: boolean = false;
    private isConfirmPopupShown: boolean = false;

    get allAlerts() {
        const { alerts = [] } = this.alertsService;
        return alerts;
    }

    get shownAlertsLength() {
        return this.isShowAll ? this.allAlerts.length : this.minAlerts;
    }

    get todayAlerts() {
        const today = new Date();
        const allTodayAlerts = this.allAlerts.filter(a => (a.date.getMonth() === today.getMonth() && a.date.getDate() === today.getDate()));
        return this.isShowAll ? allTodayAlerts : allTodayAlerts.slice(0, this.minAlerts);
    }

    get yesterdayAlerts() {
        if (!this.isShowAll && (this.minAlerts - this.todayAlerts.length < 1)) {
            return [];
        }

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const allYesterdayAlerts = this.allAlerts.filter(a => (
            a.date.getMonth() === yesterday.getMonth() && a.date.getDate() === yesterday.getDate()
        ));

        return this.isShowAll ? allYesterdayAlerts : allYesterdayAlerts.slice(0, this.minAlerts - this.todayAlerts.length);
    }

    get otherAlerts() {
        if (!this.isShowAll && (this.minAlerts - (this.todayAlerts.length + this.yesterdayAlerts.length) < 1)) {
            return [];
        }

        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const allOtherAlerts = this.allAlerts.filter(a => (
            a.date.getDate() !== yesterday.getDate()
            && a.date.getDate() !== today.getDate()
        ));

        return this.isShowAll
            ? allOtherAlerts
            : allOtherAlerts.slice(0, this.minAlerts - (this.todayAlerts.length + this.yesterdayAlerts.length));
    }

    @Watch('selectedItems.length')
    @Watch('isShowAll')
    onSelectedItemChange() {
        if (!this.selectedItems.length) {
            this.isSubSelectedItems = false;
        } else {
            this.isSubSelectedItems = this.shownAlertsLength !== this.selectedItems.length;
        }
    }

    handleMarkReadClick() {
        this.alertsService.markAllUserAlertsAsRead();
    }

    handleSelectAllChange(isShowAllChanged: boolean) {
        if (isShowAllChanged) {
            return;
        }
        if (this.isSelectedAll) {
            this.selectedItems = this.allAlerts.slice(0, this.shownAlertsLength).map(alert => alert.id);
        } else {
            this.selectedItems = [];
        }
    }

    handleAlertCheckedChange(alertId: string, isChecked: boolean) {
        if (isChecked) {
            this.selectedItems.push(alertId);
        } else {
            this.selectedItems = this.selectedItems.filter(id => id !== alertId);
        }
    }

    async handleDeleteSelectedAlerts() {
        if (this.selectedItems.length) {
            const results = await this.alertsService.deleteAlertByIds(this.selectedItems);
            if (results) {
                this.selectedItems = [];
                this.isSelectedAll = false;
            }
        }
    }

    async handleDeleteAlertById(alertId: string) {
        const results = await this.alertsService.deleteAlertById(alertId);
        if (results) {
            this.handleAlertCheckedChange(alertId, false);
        }
    }

    handleShownAlertsLengthChange() {
        this.isShowAll = !this.isShowAll;
        this.handleSelectAllChange(this.isShowAll);
    }

    isAlertChecked(alertId: string): boolean {
        return this.selectedItems.includes(alertId);
    }

    async handleDeleteAllAlerts() {
        const results = await this.alertsService.deleteAllAlerts();
        if (results) {
            this.selectedItems = [];
            this.isSelectedAll = false;
        }
    }

    confirmPopupShown() {
        this.isConfirmPopupShown = !this.isConfirmPopupShown;
    }
}

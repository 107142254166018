








import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ZendeskService, { ZendeskServiceS } from '@/modules/zendesk-chat/zendesk.service';

@Component
export default class chatButton extends Vue {
    @Inject(ZendeskServiceS) private zendeskService!: ZendeskService;

    private isEnabled: boolean = false;

    mounted() {
        setTimeout(() => {
            this.isEnabled = this.zendeskService.isEnabled();
            if (this.isEnabled) {
                this.zendeskService.init('zendesk-chat-button');
            }
        }, 5000);
    }

    openZendeskChat() {
        this.zendeskService.openChat();
    }
}

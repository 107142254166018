

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { VClosePopover } from 'v-tooltip';
import CustomPopover from '@/modules/common/components/ui-kit/custom-popover.vue';
import BranchesModel from '@/modules/cars/models/branches.model';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

export interface ICarsProfileMenuItem {
    text: string,
    link: string,
    action?: Function,
    disable?: boolean,
    isVisible?: boolean,
    viewAs?: boolean,
    isParentURL?: boolean,
    children?: ICarsProfileMenuItem[],
}

@Component({
    components: {
        CustomPopover,
    },
    directives: {
        'close-popover': VClosePopover,
    },
})
export default class CarsProfileMenu extends Vue {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    // private readonly menuThrottleTime = 2000;

    @Prop({
        required: false,
        type: String,
    })
    private label!: string;

    @Prop({
        required: false,
        type: String,
    })
    private email!: string;

    @Prop({
        required: true,
        type: Array as () => ICarsProfileMenuItem[],
        validator(value: any): boolean {
            return value.every((item: any) => ('text' in item && 'link' in item
                && typeof item.text === 'string'
                && typeof item.link === 'string'));
        },
    })
    private items!: ICarsProfileMenuItem[];

    get visibleItems(): ICarsProfileMenuItem[] {
        return this.items.filter(item => typeof item.isVisible !== 'boolean' || item.isVisible);
    }

    get chains(): BranchesModel[] {
        const { branches } = this.carsSharedService;
        return branches;
    }

    navigate(link: string, isParentURL: boolean = false) {
        const isLinkCurrent = new RegExp(`/${link}$`).test(this.$route.fullPath);
        if (isParentURL && !isLinkCurrent) {
            this.$router.push({ path: `/${link}`, replace: true });
        } else if (!isLinkCurrent) {
            this.$router.push({ path: link, append: true });
        }
    }

    setChain(value: BranchesModel) {
        this.carsSharedService.currentChain = value;
        document.location.reload();
    }
}

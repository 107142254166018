import { render, staticRenderFns } from "./whatfix-widget.vue?vue&type=template&id=f9e72e80&scoped=true&"
import script from "./whatfix-widget.vue?vue&type=script&lang=ts&"
export * from "./whatfix-widget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9e72e80",
  null
  
)

export default component.exports









import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component
export default class WalkmeWidget extends Vue {
    @Inject(UserServiceS) private userService!: UserService;

    get isWalkmeEnabled() {
        return this.userService.isWalkmeEnabled;
    }

    get firstName() {
        return this.userService.user ? this.userService.user.firstName : '';
    }

    get lastName() {
        return this.userService.user ? this.userService.user.lastName : '';
    }

    get email() {
        return this.userService.user ? this.userService.user.email : '';
    }
}

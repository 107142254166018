











































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

import AlertModel from '@/modules/alerts/models/alert.model';
import ClipText from '@/modules/common/filters/clip-text.filter';
import AlertsService, { AlertsServiceS } from '../alerts.service';
import ALERT_TYPES from '../constants/alert-types.constant';

@Component({
    filters: { ClipText },
})
export default class AlertItem extends Vue {
    @Inject(AlertsServiceS)
    private alertsService!: AlertsService;

    @Inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @Inject(MarketsCommonServiceS)
    private marketsCommonService!: MarketsCommonService;

    @Prop({
        required: true,
        type: Object,
    })
    public alert!: AlertModel;

    public isPending = false;
    public isExpanded = false;
    public isDeleteConfirmationEnabled = false;

    public targetHeight = '0';

    public get date() {
        return this.transformDate(this.alert.date);
    }

    public get linkLabel() {
        const { alert } = this;

        const { buttonLabel } = alert.extData || { buttonLabel: undefined };

        switch (alert.alertType) {
            case ALERT_TYPES.HTML_REPORT:
            case ALERT_TYPES.SCHEDULED_REPORT:
            case ALERT_TYPES.PROMOTION_DETECITON:
            case ALERT_TYPES.PROMOTION_PERCENT_CHANGE:
                return buttonLabel || 'click here';
            default:
                return buttonLabel || 'See them >';
        }
    }

    public get isHaveBehavior() {
        const { alert } = this;

        return (alert.message.includes('click here') || !!alert.extData.buttonLabel)
            && !this.link;
    }

    public get message() {
        const { alert } = this;
        const providerName = this.alertsService.getProviderName(alert.message);
        const providerLabel = providerName ? this.providersService.getProviderLabel(providerName) : null;

        if (providerName && providerLabel) {
            return alert.message.replace(providerName, providerLabel);
        }

        return alert.message;
    }

    get link() {
        const { alert } = this;
        const providerName = this.alertsService.getProviderName(alert.message);

        if (!providerName || !alert.alertType) {
            return '';
        }

        const userLevel = this.$route.name
            ? this.$route.name.split('.').shift()!
            : this.userService.userLevel;

        let ratesUrl: string = `/${userLevel}/rates/`;
        let marketsUrl: string = `/${userLevel}/markets/`;

        switch (userLevel) {
            // NOTE: Enable when chain will enabled
            // case 'chain':
            //    ratesUrl += `cluster/any/${alert.fornovaId}`; // TODO: Replace `any` by clusterId
            //    break;

            case 'cluster':
            case 'chain':
                ratesUrl += alert.fornovaId;
                break;

            default:
                ratesUrl = `/hotel/${alert.fornovaId}/rates`;
                marketsUrl = `/hotel/${alert.fornovaId}/markets`;
                break;
        }

        switch (alert.alertType) {
            case ALERT_TYPES.RATE_DROP:
                return `${ratesUrl}?provider=${providerName}`;
            case ALERT_TYPES.RATE_CHANGE:
                return `${ratesUrl}?provider=${providerName}`;
            case ALERT_TYPES.POSITION_DROP:
                return `${marketsUrl}`;
            case ALERT_TYPES.POSITION_CHANGE:
                return `${marketsUrl}`;
            case ALERT_TYPES.PROMOTION_PERCENT_CHANGE:
            case ALERT_TYPES.PROMOTION_DETECITON:
                return `/hotel/${alert.fornovaId}/promotions`;
            default:
                return '';
        }
    }

    toggleExpand() {
        this.isExpanded = !this.isExpanded;
        this.alertsService.markAlertAsRead(this.alert.id);
    }

    async markAndOpen(event: MouseEvent) {
        const { alert } = this;
        const { currentTarget } = event;

        event.preventDefault();

        await this.alertsService.markAlertAsRead(alert.id);

        window.location.href = (currentTarget as HTMLLinkElement).href;
    }

    async runAlertScript() {
        const { alert } = this;

        this.isPending = true;
        try {
            switch (alert.alertType) {
                case ALERT_TYPES.SCHEDULED_REPORT:
                    await this.resendScheduledReport();
                    break;

                case ALERT_TYPES.HTML_REPORT:
                    await this.ratesCommonService
                        .resendHTMLReport(alert.fornovaId, alert.extData.scanDate);
                    break;

                default:
                    break;
            }

            await this.alertsService
                .markAlertAsRead(alert.id);
        } finally {
            this.isPending = false;
        }
    }

    private async resendScheduledReport() {
        const { alert } = this;

        const serviceTypes = {
            rate: 'ratesCommonService',
            market: 'marketsCommonService',
        } as { [k: string]: 'ratesCommonService' | 'marketsCommonService' };

        const service = serviceTypes[alert.extData.serviceType];

        return this[service]
            .resendScheduledReport(
                alert.extData.level,
                alert.extData.schedulerId,
            );
    }

    private transformDate(date: Date): string {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return [months[date.getMonth()], date.getDate()].join(' ');
    }

    private updateTargetHeight() {
        const { container } = this.$refs as { container: HTMLDivElement };
        this.targetHeight = `${container.offsetHeight}px`;
    }

    async toggleReadStatus() {
        this.isPending = true;

        try {
            if (this.alert.read) {
                this.isExpanded = false;
                await this.alertsService
                    .markAlertAsUnread(this.alert.id);
            } else {
                await this.alertsService
                    .markAlertAsRead(this.alert.id);
            }
        } finally {
            this.isPending = false;
        }
    }

    async deleteAlert() {
        this.updateTargetHeight();
        await this.$nextTick();

        try {
            const { alert } = this;
            this.isPending = true;
            await this.alertsService.deleteAlert(alert.id);
        } finally {
            this.isPending = false;
        }
    }
}

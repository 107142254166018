






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WalkmeWidget extends Vue {
    @Prop({
        type: String,
        default: '',
    })
    email!: string;
}

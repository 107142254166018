

















import { Component, Prop, Vue } from 'vue-property-decorator';

interface SidebarMenuItem {
    to: string,
    isActive: boolean,
    iconClass: string,
    isLocked: boolean,
    title: string
}

@Component
export default class MenuItem extends Vue {
    @Prop({
        required: true,
        type: Object,
    })
    private data!: SidebarMenuItem;
}
